import { swaggerSplitApi as api } from "../swaggerApi"
import { TypeCategories } from "./types";

const categoryApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getAllCategories: build.query<TypeCategories, void>({
      query(){
        return "/api/v1/event/type/info"
      },
      async onQueryStarted(args, {dispatch, queryFulfilled}){
        const response = await queryFulfilled

      }
    })
  })
})

export const {useGetAllCategoriesQuery} = categoryApi