import { useEffect } from "react"
import { Avatar } from "@mui/material"
import { ViewsNames } from "../.."
import { UserDataForAddToBaseParticipant } from "../../../../../app/data/promotion/promotionApi"
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import AvatarFallback from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import s from "./styles.module.scss"
import LocationSpinner from "../../../../profile_settings/location/location_spinner"

type AddUserAccessProps = {
  userData: UserDataForAddToBaseParticipant
  onSetView: (view: ViewsNames) => void
  onAddParticipant: (userData: 
    Omit<UserDataForAddToBaseParticipant, 
    'ticket_url' | 'ticket_id' | 'invited_via_whatsapp' | 'invited_via_telegram'>) => void
  addParticipantSuccess: boolean
  addParticipantErrorStatus: number,
  resetAddSuccess: () => void
  patchSuccess: boolean
  resetPatch: () => void
  patchIsLoading: boolean
  addIsLoading: boolean
}

const AddUserAccess = ({
  userData,
  onSetView,
  onAddParticipant,
  addParticipantSuccess,
  addParticipantErrorStatus,
  resetAddSuccess,
  patchSuccess,
  resetPatch,
  patchIsLoading,
  addIsLoading
}: AddUserAccessProps) => {
  const { lastname, phone_number, firstname, telegram_username } = userData

  useEffect(() => {
    if (addParticipantSuccess) {
      onClickBtnGoToView(ViewsNames.SearchUser)
      resetAddSuccess()
    }
    if (patchSuccess) {
      onClickBtnGoToView(ViewsNames.SearchUser)
      resetPatch()
    }
  }, [addParticipantSuccess, patchSuccess])

  const onAddButtonAddParticipatn = () => {
      const dataForSend:
       Omit<UserDataForAddToBaseParticipant, 'ticket_url' | 'ticket_id' | 'invited_via_whatsapp' | 'invited_via_telegram'> = {
        event_id: userData.event_id,
        firstname: userData.firstname,
        lastname: userData.lastname,
        phone_number: userData.phone_number,
        telegram_username: userData.telegram_username,
        potential_participant_id: userData.potential_participant_id
      }
    onAddParticipant(dataForSend)
    console.log('click')
  }

  const onClickBtnGoToView = (view: ViewsNames) => {
    onSetView(view)
  }
  return (
    <div className={s.add_access_user}>
      <div className={s.user_data}>
        <Avatar
          //src={participant.avatar}
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
          }}
        >
          <AvatarFallback className={s.user_avatar} />
        </Avatar>
        <h4 className={s.user_name}>{`${firstname} ${lastname}`}</h4>
        <p className={s.user_phone}>{phone_number}</p>
      </div>
      { patchIsLoading || addIsLoading &&
        <div className={s.spinner_wrappep}><LocationSpinner/></div>
      }
      <div className={s.buttons_block}>
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView(ViewsNames.AddNewUser)}
          style={GradienButtonStyles.borderGradient}
          params={{ text: "Назад", disabled: false }}
        />
        <GradientButton<Object>
          onAction={() => onAddButtonAddParticipatn()}
          style={GradienButtonStyles.fullGradient}
          params={{ text: "Добавить", disabled: patchIsLoading || addIsLoading }}
        />
      </div>
    </div>
  )
}

export default AddUserAccess
